import React from "react";
import "jspdf-autotable";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactExport from "react-data-export";
import { BotonExcel } from "../Plantillas/Botones";
import moment from "moment";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


class StockDiarioUI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productos: [],
            columns: [],
            isLoading: false,
            fechaInicio: this.formatMomentForInput(moment().subtract({ days: 5 })),
            fechaFin: this.formatMomentForInput(moment())
        }

        this.formatMomentForInput = this.formatMomentForInput.bind(this);
        this.onChange = this.onChange.bind(this);
        this.generar = this.generar.bind(this);
    }

    formatMomentForInput(d) {
        return d.format('YYYY-MM-DD')
    }

    generarColumnasDeFecha () {
        const start = moment(this.state.fechaInicio);
        const end = moment(this.state.fechaFin);

        const columns = [];
        let currentDate = start.clone();
    
        while (currentDate.isSameOrBefore(end)) {
            columns.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'days');
        }
        
        this.setState({ columns });
    };

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        }, function () {
            this.generarColumnasDeFecha();
        })
    }

    async generar() {
        this.generarColumnasDeFecha();

        this.setState({ isLoading: true })

        try {
            const req = await fetch(`/api/kardex/stock-diario?fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}`)

            if (!req.ok) {
                throw new Error(req);
            }

            const res = await req.json();

            const items = res.respuesta;

            if (items.length == 0) {
                this.setState({ productos: [] })
            }

            this.setState({ productos: items })
        } catch (e) {
            console.error(e)
        } finally {
            this.setState({ isLoading: false })
        }
    }

    getDataExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: `Código`,
                        width: {wpx: 150}
                    },
                    {
                        title: `Cod. de barras`,
                        width: {wpx: 150}
                    },
                    {
                        title: `Nombre `,
                        width: {wpx: 250}
                    },
                    ...this.state.columns.map((s) => {
                        return {
                            title: moment(s).format("DD-MM"),
                            width: {wpx: 250}
                        }
                    })
                ],
                data: dataSet.map(row => {
                    return [
                        {
                            value: row.Codigo,
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: row.CodigoBarra || "",
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: row.Nombre,
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        ...this.state.columns.map((s) => {
                            return {
                                value: row[s] || 0,
                                style: {
                                    fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                                }
                            }
                        })
                    ];
                })
            }
        ];
    }

    render() {
        const dataExport = this.getDataExportExcel(this.state.productos)

        return (
            <section className="ventas-fpay-section-vh pt-5">
                <div className="container">
                    <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                        <span className="align-self-center letra-fecha-fpay">
                            Desde:
                        </span>

                        <input  type="date"
                                className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                                name="fechaInicio"
                                onChange={this.onChange}
                                value={this.state.fechaInicio}/>

                        <span className="align-self-center letra-fecha-fpay">
                            Hasta:
                        </span>

                        <input type="date"
                                className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                                name="fechaFin"
                                onChange={this.onChange}
                                value={this.state.fechaFin} />

                    </div>
                    <div className="ProcesarBoton mt-1 mb-3">
                        <div className="ProcesarBotones">
                            { this.state.productos.length ? (
                                <ExcelFile
                                    element={
                                        <BotonExcel type="button" title="Guardar Excel"/>
                                    }
                                    filename="StockDiarioProductos"
                                >
                                    <ExcelSheet
                                        dataSet={dataExport}
                                        name="Productos"
                                    >
                                    </ExcelSheet>
                                </ExcelFile>
                            ) : null}
                        </div>

                        <div className="col-sm-auto">
                            <button onClick={this.generar} disabled={this.state.isLoading}
                                    className="btn btn-outline-primary btn-verde-mfp">
                                {this.state.isLoading ? 'Cargando' : "Generar"}
                            </button>
                        </div>

                    </div>
                    <ReactTable
                        id="table-productos-to-xls"
                        data={this.state.productos}
                        pages={-1}
                        loading={this.props.tableConfig.pages.isLoading}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                        }
                        manual={true}
                        filterable={true}
                        sortable={true}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Código",
                                        id: "Codigo",
                                        width: 120,
                                        accessor: d => d.Codigo,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Codigo"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Codigo barra",
                                        id: "CodigoBarra",
                                        width: 110,
                                        accessor: d => d.CodigoBarra,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["CodigoBarra"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Nombre",
                                        id: "Nombre",
                                        width: 350,
                                        accessor: d => d.Nombre,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Nombre"]}),
                                        filterAll: true
                                    },
                                    ...this.state.columns.map((s) => {
                                        return {
                                            id: s,
                                            Header: moment(s).format("DD-MM"),
                                            Header: s,
                                            width: 150,
                                            accessor: d => d[s],
                                        }
                                    })
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        previousText="Anterior"
                        nextText="Siguiente"
                        noDataText="No se encontraron registros"
                        loadingText="Cargando..."
                        ofText="de"
                        rowsText="filas"
                        pageText="Página"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                    />
                </div>
            </section>
        );
    }
}


export default StockDiarioUI;
