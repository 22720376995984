import moment from 'moment';
import React, {Component} from "react";
import RegistrosVentasUI from "./ListaRegistrosVentasUI";
// import RegistrosVentasFpay from "../ventasfpay/registroVentasFpay";
import jsPDF from "jspdf";
import swal from "sweetalert";
import printA4, {crearDocPDF} from "../../helpers/A4";
import {enviaremail, loadLogoByRUC} from "../../helpers/funcionesGlobales";
import VentaService from "../../services/VentaService";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import {ACTION, ENTITY} from "contatrib-utils";
import {execInContext} from "../../helpers/utils";

class RegistrosVentas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ventas: [],
            IdRegistroVentas: null,
            ValidarImpresion: null,
            ple: null,
            Mes: null,
            Year: null,
            Ruc: null,
            reportePLE: null,
            xmlresponse: null,
            objCE: null,
            xml: null,
            Venta: [],
            DetallesVenta: [],
            dataSucursales: [],
            cuentasBancarias: [],
            IdSucursal: 0,
            FiltrarSucursal: false,

            mostrarBotonesImpresion: false,
            fechaBusqueda: moment(new Date()).format('YYYY-MM-DD'),
            disableAnularBtn: false,
            extImg: 'png',
            isLoading: false,
            table: {},
            isActiveTurn: false,
            codigoValidacion: "",
            codigoValidacionInput: "",
            permisosAnular: false,
            showModalValidacion: false,
            facturacionInfo: null
        };
        this.conf = JSON.parse(window.localStorage.getItem('extraConf'))
        this.ventaService = new VentaService();
        this.handleVerVenta = this.handleVerVenta.bind(this);
        this.handleSavePDFList = this.handleSavePDFList.bind(this);
        this.handleDescargarPDF = this.handleDescargarPDF.bind(this);
        this.exportTxtPLE = this.exportTxtPLE.bind(this);
        this.getRUC = this.getRUC.bind(this);
        this.downloadPLE = this.downloadPLE.bind(this);
        this.handleEnviarVenta = this.handleEnviarVenta.bind(this);
        this.handleEnviarAnulacion = this.handleEnviarAnulacion.bind(this);
        this.handleEnviarCorreo = this.handleEnviarCorreo.bind(this);
        this.TraerSucursales = this.TraerSucursales.bind(this);
        this.traerFechaBase = this.traerFechaBase.bind(this);
        this.RestarMontoComoMovimientoSalida = this.RestarMontoComoMovimientoSalida.bind(this);
        this.onRegresarStock = this.onRegresarStock.bind(this);
        this.setTablaVentasState = this.setTablaVentasState.bind(this)
        this.onFilterVentas = this.onFilterVentas.bind(this)
        this.handlefechaBusquedaChange = this.handlefechaBusquedaChange.bind(this);
        this.onReportHanler = this.onReportHanler.bind(this);
        this.fetchNumerosCuenta = this.fetchNumerosCuenta.bind(this);
        this.fetchCodigoValidacion = this.fetchCodigoValidacion.bind(this);
        this.permisosAnularComprobante = this.permisosAnularComprobante.bind(this);
        this.openModalValidacion = this.openModalValidacion.bind(this);
        this.handleCloseModalValidarAnular = this.handleCloseModalValidarAnular.bind(this);
        this.handleChangeCodigoAnular = this.handleChangeCodigoAnular.bind(this);
        this.validarCodigoUsuario = this.validarCodigoUsuario.bind(this);
        this.handleQueryTicketBaja = this.handleQueryTicketBaja.bind(this);
    }

    async componentDidMount() {
        const Fecha = new Date();
        await execInContext(this)
        await this.traerFechaBase()
        await this.fetchNumerosCuenta()
        await this.permisosAnularComprobante()
        await this.fetchCodigoValidacion()
        this.setState({
            Mes: Fecha.getMonth(),
            Year: Fecha.getFullYear(),
        });
        await this.DatosUser();
        await this.getRUC();
    }

    async fetchCodigoValidacion() {
        this.setState({loading: true});
        let res = await fetch('/api/usuarios/codigoConfirmacion/sucursal');
        const codigoValidacion = await res.json();
        //console.log({codigoValidacion: codigoValidacion})
        try {
            if (res.ok)
                var codigo = codigoValidacion[0].Codigo
            this.setState({codigoValidacion: codigo})
        } catch (e) {
            //
        }
        //console.log({codigoValidacion: this.state.codigoValidacion})
        this.setState({loading: false});
    }

    openModalValidacion(objAnular) {
        this.setState({facturacionInfo: objAnular})
        this.setState({showModalValidacion: true})
    }

    async validarCodigoUsuario() {
        const codigoInput = this.state.codigoValidacionInput;
        const codigoUsuario = this.state.codigoValidacion;
        const facturacion = this.state.facturacionInfo;
        if (codigoInput != codigoUsuario) {
            notificarMsg('El codigo ingresado no es correcto.', 'warning')

        }
        if (codigoInput == codigoUsuario) {
            notificarMsg("Validacion completada exitosamente.");
            await this.handleEnviarAnulacion(facturacion)
        }
        this.setState({showModalValidacion: false})
        this.setState({codigoValidacionInput: ""})
    }

    handleChangeCodigoAnular = (text) => {
        const value = text.target.value
        this.setState({codigoValidacionInput: value});
    }

    async permisosAnularComprobante() {
        try {
            const responseVal = await fetch('/api/usuarios/anularComprobante/validar');
            const data = await responseVal.json();
            this.setState({permisosAnular: true})
        } catch (e) {
            this.setState({permisosAnular: false})
        }
    }

    handleCloseModalValidarAnular = (e) => {
        this.setState({
            showModalValidacion: false
        })
    }

    traerFechaBase = async () => {
        try {
            const response = await fetch(`/api/ventas/traerFecha/Base`);
            const fechaBusqueda = await response.json();
            this.setState({
                fechaBusqueda: fechaBusqueda.respuesta[0].fechaBusqueda
            })
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async fetchNumerosCuenta() {
        this.setState({loading: true});
        let res = await fetch('/api/cuentas/numeroscuenta');
        const cuentas = await res.json();
        // console.log({cuentas: cuentas})
        if (res.ok)
            this.setState({cuentasBancarias: cuentas})
        this.setState({loading: false});
    }

    RestarMontoComoMovimientoSalida = async (objAnular) => {

        try {
            await this.fetchRestarMonto({
                IdRegistroVenta: objAnular.cabecera.IdRegistroVenta,
                Serie: objAnular.items[0].Serie,
                NroComprobante: objAnular.items[0].NroComprobante,
            })
        } catch (err) {

        }
    }

    async fetchRestarMonto(data) {
        await fetch(`/api/cajas/addMov/anulacionMov`, {
            method: 'POST',
            body: JSON.stringify({
                IdRegistroVenta: data.IdRegistroVenta,
                Observacion: "Anulación del comprobante: " + data.Serie + " - " + data.NroComprobante
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }

    async DatosUser() {
        try {

            const response = await fetch(`/api/configFactElectronica/User`);
            const data = await response.json();

            this.setState({
                IdSucursal: data.objuser.IdSucursal,
                extImg: data.extImg,
                isActiveTurn: data.isActiveTurn
            })

            await this.ValidarRol()
            await this.onFilterVentas(this.state.table)

        } catch (error) {
        }
    }

    async ValidarRol() {

        try {
            const response = await fetch(`/api/configFactElectronica/ChangeSucursal`);
            const data = await response.json();

            this.setState({
                FiltrarSucursal: true
            })
        } catch (error) {
            this.setState({
                FiltrarSucursal: false
            })
        }
        await this.TraerSucursales();

    }

    async getPLE(Mes, Year) {
        try {
            const response = await fetch(`/api/ventas/ple/${Mes}/${Year}`);
            const ple = await response.json();
            this.setState({ple: ple.respuesta, isLoading: false});
            return ple;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReportePLE2(fechaBusqueda) {
        try {
            this.setState({isLoading: true, mostrarBotonesImpresion: false})
            const response = await fetch(
                `/api/ventas/ple2/reporte2/${fechaBusqueda}`
            );
            const reportePLE = await response.json();
            this.setState({
                reportePLE: reportePLE.respuesta,
                isLoading: false,
                mostrarBotonesImpresion: reportePLE.respuesta.length > 0
            });
            if (reportePLE.respuesta.length === 0)
                notificarMsg("No existen datos para la fecha seleccionada.")
            return reportePLE;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getRUC() {
        try {
            const response = await fetch(`/api/ventas/ruc`);
            const Ruc = await response.json();
            this.setState({Ruc: Ruc.respuesta[0].Ruc});
        } catch (error) {
            this.setState({error});
        }
    }

    downloadPLE(filename, text) {
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    async exportTxtPLE() {
        const Mes = this.state.Mes + 1;
        const Year = this.state.Year;

        const ple = await this.getPLE(Mes, Year);

        if (ple.respuesta.length > 0) {
            const pleFixed = ple.respuesta.map(ple => ple.PLE).join("\n");

            /** CREANDO EL NOMBRE DEL ARCHIVO CON LA NOMENCLATURA EXIGIDA EN LA SUNAT **************/
            /** DOCUMENTACIÓN SE ENCUENTRA EN PAGINA DE SUNAT: NOMENCLATURA DE LIBROS ELECTRÓNICOS**/

            const IDFIJO = "LE";
            const RUC = this.state.Ruc;
            const YEAR = this.state.Year;
            let MES = (this.state.Mes + 1).toString();
            MES = MES.padStart(2, "0");
            const DIA = "00";
            const IDLIBRO = "140100";
            const CODOPORTUNIDAD = "00";
            const IDOPERACIONES = 1;
            const IDCONTENIDO = 1;
            const MONEDA = 1;
            const IDPLEFIJO = 1;

            const nomenclaturaPLE =
                IDFIJO +
                RUC +
                YEAR +
                MES +
                DIA +
                IDLIBRO +
                CODOPORTUNIDAD +
                IDOPERACIONES +
                IDCONTENIDO +
                MONEDA +
                IDPLEFIJO;

            this.downloadPLE(nomenclaturaPLE, pleFixed);
        } else {
            swal(
                "ERROR",
                "NO EXISTE REGISTRO DE VENTAS EN LA FECHA SELECCIONADA",
                "error"
            );
        }
    }

    async handlefechaBusquedaChange(e) {
        let fechaBusqueda = e.target.value
        await this.setState({
            fechaBusqueda: fechaBusqueda,
            ventas: [],
            mostrarBotonesImpresion: false
        });

        this.onFilterVentas(this.state.table)
    }

    handleVerVenta(IdRegistroVentas) {
        return () => {
            this.props.history.push(`/detalleregistroventas/${IdRegistroVentas}`);
        };
    }

    handleEnviarVenta(IdRegistroVentas) {
        return async () => {
            await this.sendxml(IdRegistroVentas);
            await this.onFilterVentas(this.state.table)
        };
    }

    handleDescargarXML(nameFile) {
        this.setState({
            xml: nameFile,
        });
    }

    async sendxml(IdRegistroVentas) {
        try {
            this.setState({isLoading: true})
            const response = await fetch(`/api/configFactElectronica/ce/${IdRegistroVentas}`);
            const rpta = await response.json();
            if (!rpta.ok) {
                swal({
                    title: 'Error de Envío ',
                    text: `El Comprobante Electrónico no fué enviado al Operador`,
                    icon: 'warning'
                })
            } else {
                swal({
                    title: 'Envío Satisfactorio...! ',
                    text: `El Comprobante fué enviado al Operador verifique el Código de Respuesta`,
                    icon: 'success'
                })
            }
            this.setState({isLoading: false});
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async handleEnviarCorreo(data) {


        if (data.CorreoElectronico) {
            await this.getVenta(data.IdRegistroVenta);
            await this.getDetalleVenta(data.IdRegistroVenta);
            var docpdf = await crearDocPDF(this.state.DetallesVenta, this.state.Venta, this.state.extImg, this.state.cuentasBancarias);

            let rpta = await enviaremail(data, docpdf);

            await this.onFilterVentas(this.state.table)

            if (rpta) {
                swal({
                    title: 'El correo fue enviado con éxito...! ',
                    text: `Cuenta destino: ${data.CorreoElectronico}`,
                    icon: 'success'
                })
            } else {
                swal({
                    title: 'Correo no enviado, intente luego... ',
                    text: `Cuenta destino: ${data.CorreoElectronico}`,
                    icon: 'warning'
                })
            }
        } else {
            swal({
                title: 'Atención...! ',
                text: `No cuenta con una cuenta de correo`,
                icon: 'info'
            })
        }
    }

    async handleEnviarAnulacion(objAnular) {
        const ff = moment(new Date());
        const fi = moment(objAnular.items[0].FechaEmision, 'DD-MM-YYYY')
        const diferencia = ff.diff(fi, 'days')


        if (diferencia >= 6) {
            swal({
                title: `Anulación de Comprobante`,
                text: `El Comprobante ${objAnular.items[0].Serie}-${objAnular.items[0].NroComprobante}, tiene Fecha de Emisión ${objAnular.items[0].FechaEmision} y supera el período de envío dentro de los (6) días.`,
                icon: 'info'
            })
        } else {
            if (this.conf.useAccessCode) {
                const execInfo = {
                    method: "deleteVenta",
                    data: objAnular
                }
                const data = JSON.stringify({
                    action: ACTION.DELETE,
                    entity: ENTITY.VENTA,
                    entityId: objAnular.cabecera.IdRegistroVenta,
                    redirect: `/facturacionelectronica?exec=${JSON.stringify(execInfo)}`,
                    description: `Anulación del comprobante ${objAnular.items[0].Serie}-${objAnular.items[0].NroComprobante}`
                })

                this.props.history.push(`/check-access/?data=${data}`)
            } else {
                await this.deleteVenta(objAnular);
            }
        }
    }

    async deleteVenta(objAnular) {
        let isOK = await swal({
            title: 'Cuidado...! ',
            text: `Desea Anular el Comprobante: ${objAnular.items[0].Serie}-${objAnular.items[0].NroComprobante}?`,
            icon: 'error',
            buttons: [
                'No',
                'Si'
            ],
        })

        if (isOK) {
            this.setState({disableAnularBtn: true})
            let rpta = await this.sendxmlanulacion(objAnular);
            this.setState({disableAnularBtn: false})

            if (rpta.ok) {
                if (!rpta.regresoStock)
                    await this.RestarMontoComoMovimientoSalida(objAnular);

                swal({
                    title: `Anulación de Comprobante ${objAnular.items[0].Serie}-${objAnular.items[0].NroComprobante} Exitosa...! `,
                    text: `Nro Ticket de Anulación: ${rpta.ticket}`,
                    icon: 'success'
                })
            } else {
                swal({
                    title: 'Anulación de Comprobante Fallida: ' + rpta.msg || "",
                    text: rpta.message,
                    icon: 'warning'
                })
            }

            await this.onFilterVentas(this.state.table)
        }
    }

    async sendxmlanulacion(comprobante) {
        try {
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "entity": ENTITY.VENTA,
                    "action": ACTION.DELETE,
                    "entityId": comprobante.cabecera.IdRegistroVenta,
                    "accessCode": comprobante.accessCode,
                },
                body: JSON.stringify(comprobante)
            };
            const response = await fetch(`/api/configFactElectronica/ce/baja`, config);

            if (response.status === 429) {
                return {ok: false, msg: "Solo se puede hacer una anulación por minuto."}
            } if (response.status === 400) {
                return {ok: false, msg: "El comprobante ya ha sido anulado previamente."}
            } if (response.status > 400) {
                return {ok: false, msg: "No tiene permiso o ha ocurrido otro error."}
            }
            return await response.json();
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getVenta(IdRegistroVenta) {
        await fetch(`/api/ventas/get/${IdRegistroVenta}`)
            .then(response => response.json())
            .then(Venta => {
                    this.setState({
                        Venta: Venta.respuesta[0],
                        isLoading: false
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getDetalleVenta(IdRegistroVenta) {
        await fetch(`/api/ventas/detalles/${IdRegistroVenta}`)
            .then(response => response.json())
            .then(DetallesVenta =>
                this.setState({
                    DetallesVenta: DetallesVenta.respuesta,
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async handleDescargarPDF(IdRegistroVenta, doPrint) {
        await this.getVenta(IdRegistroVenta);
        await this.getDetalleVenta(IdRegistroVenta);
        await printA4(this.state.DetallesVenta, this.state.Venta, this.state.extImg, this.state.cuentasBancarias, doPrint);
    }

    async handleQueryTicketBaja(IdRegistroVenta, resSunat) {
        const ticket = resSunat.ticket;
        if (!/^\d+$/.test(ticket)) {
            swal({
                title: 'Error de Envío ',
                text: `El ticket de baja no es válido`,
                icon: 'warning'
            })
            return;
        }

        try {
            const response = await fetch(`/api/configFactElectronica/ce/baja/${IdRegistroVenta}/ticket`);

            const rpta = await response.json();

            if (rpta.ok) {
                swal({
                    title: 'Ticket de baja consultado con éxito',
                    text: `La anulación del comprobante ${resSunat.serie}-${resSunat.numero} fue aceptada por la SUNAT`,
                    icon: 'success'
                });

                this.handlefechaBusquedaChange({ target: { value: this.state.fechaBusqueda }})
            }
        } catch (e) {
            console.error(e)

            swal({
                title: 'Error de Envío ',
                text: `No se pudo consultar el ticket de baja`,
                icon: 'error'
            });
        }
    }

    TraerSucursales = async () => {
        this.setState({error: null});
        try {
            const response = await fetch(`/api/configFactElectronica/SucursalEmpresa`);
            const dataSuc = await response.json();
            this.setState({
                dataSucursales: dataSuc,
            });

        } catch (error) {
            this.setState({
                error: error,
            });
        }
    };

    SucursalChange = async e => {
        await this.setState({
            IdSucursal: parseInt(e.target.value),
            ventas: [],
        })

        this.onFilterVentas(this.state.table)
    }

    handleSavePDFList = async (vEntas) => {
        const mmPageSize = [760, 450];
        const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
        let doc = new jsPDF({
            orientation: "landscape",
            format: ptPageSize
        });

        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        doc.setFontSize(14);
        doc.setFontType("bold");
        doc.text('RELACIÓN DEL LIBRO ELECTRÓNICO DE VENTAS ' + this.props.sesion.RazonSocial, 17, 20)
        doc.text("RUC: " + this.state.Ruc, 17, 30);
        doc.setFontType("normal");
        doc.text(`FECHA: ${this.state.fechaBusqueda}`, 17, 40)

        const logoP = loadLogoByRUC(this.state.Ruc, 'logo', this.state.extImg);
        const logo = await logoP;
        doc.addImage(logo, "PNG", pageWidth - 110, 10, 90, 20);

        doc.setFontSize(10);


        doc.autoTable({
            startY: 50,
            body: vEntas,
            theme: "plain",
            styles: {lineWidth: 0.5},

            columns: [
                {header: 'Codigo Unico Operacion', dataKey: 'CodigoUnicoOperacion'},
                {header: 'Fecha Emision', dataKey: 'FechaEmision'},
                {header: 'Tipo Comprobante', dataKey: 'TipoComprobante'},
                {header: 'Serie', dataKey: 'Serie'},
                {header: 'Numero Comprobante', dataKey: 'NumeroComprobante'},
                {header: "Estado Del Comprobante", dataKey: "Estado"},
                {header: "Modalidad de Pago", dataKey: "IdModalidadPago"},
                {header: "Dias de Credito", dataKey: "CantidadDiasCredito"},
                {header: "Fecha de Pago", dataKey: "FechaPago"},
                {header: 'Nro Tipo Doc. Cliente', dataKey: 'NroTipoDocumentoCliente'},
                {header: 'Razon Social', dataKey: 'RazonSocial'},
                {header: 'Exportacion', dataKey: 'Exportacion'},
                {header: 'Gravadas', dataKey: 'Gravadas'},
                {header: 'Exoneradas', dataKey: 'Exoneradas'},
                {header: 'Inafectas', dataKey: 'Inafectas'},
                {header: 'ISC', dataKey: 'ISC'},
                {header: 'IGV', dataKey: 'IGV'},
                {header: 'ICBPER', dataKey: 'ICBPER'},
                {header: 'Total', dataKey: 'Total'},
                {header: 'Redondeo', dataKey: 'Redondeo'},
                {header: 'Total Redondeado', dataKey: 'TotalRedondeo'},
                {header: 'TipoCambio', dataKey: 'TipoCambio'},
                {header: 'Fecha Comprobante Modificado', dataKey: 'FechaComprobanteModificado'},
                {header: 'Codigo Tipo Comprobante Modificado', dataKey: 'CodigoTipoComprobanteModificado'},
                {header: 'Serie Comprobante Modificado', dataKey: 'SerieComprobanteModificado'},
                {header: 'Numero Comprobante Modificado', dataKey: 'NumeroComprobanteModificado'}
            ],


        }).setFontSize(11);
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("MiFacturaPeru ERP", pageWidth - 7, pageHeight - 7, "right");
        doc.save("relación_Ventas.pdf");
    }

    async onRegresarStock(venta) {
        if (this.conf.useAccessCode) {
            const objAnular = {
                IdRegistroVenta: venta.IdRegistroVenta,
                Serie: venta.Serie,
                NumeroComprobante: venta.NumeroComprobante,
            }
            const execInfo = {
                method: "restoreOnlyStock",
                data: objAnular
            }
            const data = JSON.stringify({
                action: ACTION.DELETE,
                entity: ENTITY.VENTA,
                entityId: objAnular.IdRegistroVenta,
                redirect: `/facturacionelectronica?exec=${JSON.stringify(execInfo)}`,
                description: `Anular comprobante manual ${objAnular.Serie}-${objAnular.NumeroComprobante}`
            })
            this.props.history.push(`/check-access/?data=${data}`)
        } else {
            await this.restoreOnlyStock(venta);
        }
    }

    async restoreOnlyStock(venta) {
        let isOK = await swal({
            title: 'Cuidado...! ',
            text: `Esta opción no tiene restauración ¿Desea  anular el comprobante manual?: ${venta.Serie}-${venta.NumeroComprobante}?`,
            icon: 'error',
            buttons: [
                'No',
                'Si'
            ],
        })

        if (isOK) {
            this.setState({isLoading: true})
            let res = await this.ventaService.restarStock(venta);
            if (res) {
                notificarMsg('Se ha restaurado el stock con éxito')
            } else
                notificarError('No se ha podido restaurar el stock')
            await this.onFilterVentas(this.state.table)
            this.setState({isLoading: false})
        }
    }

    async onFilterVentas(state, instance) {

        const params = {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }
        this.setTablaVentasState({isLoading: true, ...params})

        let ventas = String(this.state.IdSucursal) === "0" ? {ventas: []} : await this.ventaService.filterVentasCe(params,
            {idSucursal: this.state.IdSucursal || "0", fecha: this.state.fechaBusqueda})

        if (ventas) {
            this.setState({
                ventas: ventas.ventas,
            })
            this.setTablaVentasState({pages: ventas.pages || "0"})
        } else
            notificarError("No se ha podido filtrar las ventas")
        this.setTablaVentasState({isLoading: false})
    }

    setTablaVentasState(newState) {
        this.setState((localState, props) => {
            return {
                table: {
                    ...localState.table,
                    ...newState
                }
            }
        })
    }

    async onReportHanler() {
        const willGenerate = await swal({
            title: `Reporte de excel de todas las ventas en la fecha ${moment(this.state.fechaBusqueda).format('DD-MM-YYYY')}`,
            text: "Esto va a tomar un tiempo acorde a la cantidad de ventas realizadas, ¿está seguro?",
            icon: "info",
            buttons: true,
            closeOnClickOutside: false
        });
        if (willGenerate)
            this.getReportePLE2(this.state.fechaBusqueda);
    }

    render() {
        return (
            <>
                <div>
                    <RegistrosVentasUI
                        {...this.state}
                        conf={this.conf}
                        tableInfo={this.state.table}
                        onFilterVentas={this.onFilterVentas}
                        onRegresarStock={this.onRegresarStock}
                        handlefechaBusquedaChange={this.handlefechaBusquedaChange}
                        disableAnularBtn={this.state.disableAnularBtn}
                        handleVerVenta={this.handleVerVenta}
                        pdfViewer={this.handleSavePDFList}
                        handleEnviarVenta={this.handleEnviarVenta}
                        handleDescargarXML={this.handleDescargarXML}
                        onDownloadPdf={this.handleDescargarPDF}
                        handleEnviarAnulacion={this.handleEnviarAnulacion}
                        openModalValidacion={this.openModalValidacion}
                        handleEnviarCorreo={this.handleEnviarCorreo}
                        dataSucursales={this.state.dataSucursales}
                        SucursalChange={this.SucursalChange}
                        FiltrarSucursal={this.state.FiltrarSucursal}
                        IdSucursal={this.state.IdSucursal}
                        onReportHandler={this.onReportHanler}
                        handleCloseModalValidarAnular={this.handleCloseModalValidarAnular}
                        handleChangeCodigoAnular={this.handleChangeCodigoAnular}
                        validarCodigoUsuario={this.validarCodigoUsuario}
                        handleQueryTicketBaja={this.handleQueryTicketBaja}
                    />
                </div>
            </>
        );
    }
}

export default RegistrosVentas;
