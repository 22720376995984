

export default class CombosService {
    async fetchDetail(id) {
        const req = await fetch(`/api/combos/${id}`);

        if (!req.ok) {
            throw new Error("No se pudo obtener el detalle del combo.");
        }

        return await req.json();
    }

    async fetchAll(onlyActive = true) {
        const req = await fetch('/api/combos');

        if (!req.ok) {
            throw new Error("No se pudo obtener el listado de combos.");
        }

        const combos = await req.json();

        if (onlyActive) {
            return combos.filter(c => c.Estado == "Activo")
        }

        return combos;
    }

    async buscarProducto(nombre) {
        const req = await fetch(`/api/preVentas/?search=${nombre}&idTipoStock=1`);

        if (!req.ok) {
            throw new Error("No se puede buscar el producto.");
        }

        const res = await req.json();

        return res.productos;
    }
}