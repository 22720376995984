import React from "react";
import PresentacionPicker from "../PresentacionePicker";


export default class UpdateDefaultDiscountJobForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {},
        }
        this.onChangeDescuento = this.onChangeDescuento.bind(this)
        this.onDeleteProd = this.onDeleteProd.bind(this)
        this.onGuardar = this.onGuardar.bind(this)
        this.onAddProd = this.onAddProd.bind(this)
    }

    onChangeDescuento(e) {
        const idProd = e.target.getAttribute('data-id-prod')
        const priceAttr = e.target.getAttribute("name")
        const items = [...this.state.payload.items]
        const item = items.find(x => String(x.idPresentacion) === String(idProd))

        if (item) {
            item[priceAttr] = +e.target.value
            this.setState((state, props) => {
                return {
                    payload: {
                        ...state.payload,
                        items: items
                    }
                }
            })
        }
    }

    onDeleteProd(e) {
        const idProd = e.target.getAttribute('data-id-prod')
        const items = [...this.state.payload.items]
        const item = items.find(x => String(x.idPresentacion) === String(idProd))
        if (item) {
            items.splice(items.indexOf(item), 1)
            this.setState((state, props) => {
                return {
                    payload: {
                        ...state.payload,
                        items: items
                    }
                }
            })
        }
    }

    onAddProd() {
        this.setState((state, props) => {
            const payload = state.payload
            const items = payload.items || []
            const founded = items.find(i => String(i.idPresentacion) === String(payload.selectedProd.IdPresentacion))
            if (!founded) {
                items.push({
                    label: payload.selectedProd.label,
                    idPresentacion: payload.selectedProd.IdPresentacion,
                    descuento: 0,
                })
                return {
                    payload: {
                        ...state.payload,
                        items: items
                    }
                }
            }
        })
    }

    render() {
        const {payload} = this.state
        return (
            <>
                <div className="row">
                    <div className="col-sm-auto">
                        <button className="btn btn-link" onClick={() => this.props.onRegresar()}>Regresar</button>
                    </div>
                    <div className="col-sm">
                        <h3>Crear trabajo programado - Actualizar descuento predefinido</h3>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-sm">
                        <div className="form-group">
                            <label>Fecha de inicio*</label>
                            <input onChange={e => {
                                const value = e.target.value
                                this.setState((state, props) => {
                                    return {
                                        payload: {
                                            ...state.payload,
                                            fechaInicio: value
                                        }
                                    }
                                })
                            }} type="date" className="form-control"/>
                            <input onChange={e => {
                                const value = e.target.value
                                this.setState((state, props) => {
                                    return {
                                        payload: {
                                            ...state.payload,
                                            horaInicio: value
                                        }
                                    }
                                })
                            }} type="time" className="form-control mt-2"/>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <div className="form-group">
                            <label>Fecha de fin*</label>
                            <input onChange={e => {
                                const value = e.target.value
                                this.setState((state, props) => {
                                    return {
                                        payload: {
                                            ...state.payload,
                                            fechaFin: value
                                        }
                                    }
                                })
                            }} type="date" className="form-control"/>
                            <input onChange={e => {
                                const value = e.target.value
                                this.setState((state, props) => {
                                    return {
                                        payload: {
                                            ...state.payload,
                                            horaFin: value
                                        }
                                    }
                                })
                            }} type="time" className="form-control mt-2"/>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <div className="form-group">
                            <label>Nombre</label>
                            <input type="text" className="form-control" value={payload.name} onChange={e => {
                                const value = e.target.value
                                this.setState((state, props) => {
                                    return {
                                        payload: {
                                            ...state.payload,
                                            name: value
                                        }
                                    }
                                })
                            }}/>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <PresentacionPicker required onChangePresentacion={prods => this.setState((state, props) => {
                            return {
                                payload: {
                                    ...state.payload,
                                    selectedProd: prods && prods.length ? prods[0] : null
                                }
                            }
                        })}/>
                    </div>
                    <div className="col-sm">
                        <button onClick={this.onAddProd} className="btn btn-outline-secondary mt-4">
                            Agregar
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Descuento Inicio</th>
                                <th>Descuento Fin</th>
                                <th>Quitar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(payload.items || []).map((d, i) => <tr key={`${d.idPresentacion}-${i}`}>
                                <td>{i + 1}</td>
                                <td>{d.label}</td>
                                <td>
                                    <input onChange={this.onChangeDescuento} data-id-prod={d.idPresentacion}
                                           type="number"
                                           name="descuentoInicio"
                                           value={d.descuentoInicio} className="form-control"/>
                                </td>
                                <td>
                                    <input onChange={this.onChangeDescuento} data-id-prod={d.idPresentacion}
                                           type="number"
                                           name="descuentoFin"
                                           value={d.descuentoFin} className="form-control"/>
                                </td>
                                <td>
                                    <button
                                        onClick={this.onDeleteProd}
                                        data-id-prod={d.idPresentacion}
                                        className="btn btn-outline-danger">
                                        Quitar
                                    </button>
                                </td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <button disabled={Boolean(this.props.isLoading)} className="btn btn-outline-primary"
                                onClick={this.onGuardar}>
                            Guardar
                        </button>
                    </div>
                </div>
            </>
        );
    }

    onGuardar() {
        this.props.onAgregar(this.state.payload)
    }
}
