import React, {Component} from "react";
import ValesUI from "./ValesUI";
import jsPDF from "jspdf";
import moment from "moment";
import AsyncSelect from 'react-select/async';
import {notificarError, notificarMsg} from "../../componentes/Almacenes/AlmacenNotify";
import swal from "sweetalert";
import {ACTION, ENTITY} from "contatrib-utils";
import {execInContext} from "../../helpers/utils";
import {pfetch} from "../../helpers/wrappers";
import SucursalPicker from "../../componentes/ReporteMovAlmacen/SucursalPicker";


const VALES_TIME_RANGE_SESSION_KEY = "list-vales-time-range";


class Vales extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vales: [],
            cliente: {},
            IdVale: null,
            fechaInicio: moment(new Date()).format("YYYY-MM-DD"),
            fechaFin: moment(new Date()).format("YYYY-MM-DD"),
            hasEditPerm: false,
            total: 0,
            totalPendientes: 0,
            totalSelected: 0,
            hasDeletePerm: false,
            canSelectSucursal: false,
            idSucursal: null,

            permisosAnular: false,
            valeInfo: null,
            codigoValidacion: null,
            codigoValidacionInput: "",
            showModalValidacion: false,
        };
        this.conf = JSON.parse(window.localStorage.getItem('extraConf'))
        this.getVales = this.getVales.bind(this);
        this.handleVerVale = this.handleVerVale.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.anularValeHandler = this.anularValeHandler.bind(this);
        this.onChangeFechaInicioHandler = this.onChangeFechaInicioHandler.bind(this);
        this.onChangeFechaFinHandler = this.onChangeFechaFinHandler.bind(this);
        this.onChangeCliente = this.onChangeCliente.bind(this);
        this.onCheckVale = this.onCheckVale.bind(this);
        this.onProcesarVales = this.onProcesarVales.bind(this);
        this.fetchValesPerm = this.fetchValesPerm.bind(this);
        this.onSelectAllVales = this.onSelectAllVales.bind(this)
        this.reCalculateVales = this.reCalculateVales.bind(this)
        this.goToPreventa = this.goToPreventa.bind(this)
        this.permisosAnularComprobante = this.permisosAnularComprobante.bind(this);
        this.openModalValidacion = this.openModalValidacion.bind(this)
        this.validarCodigoUsuario = this.validarCodigoUsuario.bind(this)
        this.handleCloseModalValidarAnular = this.handleCloseModalValidarAnular.bind(this)
        this.fetchCodigoValidacion = this.fetchCodigoValidacion.bind(this)
        this.onSelectTwentyVales = this.onSelectTwentyVales.bind(this)
    }

    onChangeCliente(test) {
        this.setState({
            cliente: test
        })
    }

    async componentDidMount() {
        await execInContext(this)
        await this.fetchValesPerm();
        await this.fetchCodigoValidacion()
        await this.permisosAnularComprobante()

        const timeRange = window.sessionStorage.getItem(VALES_TIME_RANGE_SESSION_KEY)

        if (timeRange) {
            const {fechaInicio, fechaFin} = JSON.parse(timeRange)
            this.setState({fechaInicio, fechaFin})
            this.getVales(fechaInicio, fechaFin)
        } else {
            this.getVales(this.state.fechaInicio, this.state.fechaFin);
        }
    }

    async fetchValesPerm() {
        let res = await fetch('/api/vales/has-perms')
        if (res.ok) {
            const data = await res.json()
            this.setState({hasEditPerm: data.hasEditPerm, hasDeletePerm: data.hasDeletePerm})
        }
    }

    async fetchCodigoValidacion() {
        this.setState({loading: true});

        try {
            const req = await fetch('/api/usuarios/codigoConfirmacion/sucursal');

            if (!req.ok) {
                throw new Error('No fue posible obtener el código de confirmación')
            }

            const res = await req.json();
            this.setState({ codigoValidacion: res[0].Codigo})
        } catch (e) {
            console.error(e)
        } finally {
            this.setState({ loading: false });
        }
    }

    async anularValeHandler(vale) {
        if (this.conf.useAccessCode) {
            const execInfo = {
                method: "askForDelete",
                data: {
                    Serie: vale.Serie,
                    NumeroComprobante: vale.NumeroComprobante,
                    IdVale: vale.IdVale
                }
            }
            const data = JSON.stringify({
                action: ACTION.UPDATE,
                entity: ENTITY.VALE,
                entityId: vale.IdVale,
                redirect: `/vales?exec=${JSON.stringify(execInfo)}`,
            })
            this.props.history.push(`/check-access/?data=${data}`)
        } else {
            this.askForDelete(vale);
        }
    }

    async permisosAnularComprobante() {
        try {
            const responseVal = await fetch('/api/usuarios/anularComprobante/validar');
            const data = await responseVal.json();
            this.setState({permisosAnular: true})
        } catch (e) {
            this.setState({permisosAnular: false})
        }
    }

    askForDelete(vale) {
        swal({
            title: "Anular vale",
            text: `¿Está seguro de anular el vale: ${vale.Serie}-${vale.NumeroComprobante}?`,
            icon: 'error',
            buttons: [
                'No',
                'Si'
            ],
        }).then(async r => {
            if (r) {
                this.setState({isLoading: true});
                pfetch({
                        fetch: {
                            url: `/api/vales/anular-vale`,
                            method: "POST",
                            body: JSON.stringify({
                                IdVale: vale.IdVale
                            }),
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                "entity": ENTITY.VALE,
                                "action": ACTION.DELETE,
                                "entityId": vale.IdVale,
                                "accessCode": vale.accessCode,
                            }
                        },
                        before: () => {
                            if (!this.state.isLoading)
                                this.setState({isLoading: true})
                        },
                        then: () => notificarMsg("Se anuló correctamente"),
                        forbidden: () => notificarError("No tiene permiso para realizar la acción"),
                        catch: () => notificarError("Ha ocurrido un error al momento de anular"),
                        finally: () => {
                            this.getVales(this.state.fechaInicio, this.state.fechaFin)

                            if (this.state.isLoading)
                                this.setState({isLoading: false})
                        }
                    }
                )
            }
        })
    }

    searchClients(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/clientes/buscar/?query=${query}`)
                .then(r => r.json())
                .then(clientes => {
                    const mappedClientes = clientes.map(c => {
                        return {
                            label: `${c.PrimerNombre} ${c.SegundoNombre || ""} - ${c.RazonSocial || ""} ${c.NroTipoDocumento || ""}`,
                            value: c.IdCliente
                        }
                    });
                    resolve(mappedClientes)
                }).catch(reject)
        })
    }

    getVales(fechaInicio, fechaFin, idCliente, idSucursal = null) {
        const queryIdCliente = idCliente ? `&idCliente=${idCliente}` : '';
        const queryIdSucursal = idSucursal ? `&idSucursal=${idSucursal}` : ''
        this.setState({isLoading: true})
        fetch(`/api/vales/pendientes?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}${queryIdCliente}${queryIdSucursal}`)
            .then(response => response.json())
            .then(vales => {
                    this.setState({
                        vales: vales.respuesta.map(v => {
                            return {...v, isChecked: false, _tipoPagos: this.extractTiposPagos(v.tipoPagos)}
                        }),
                        total: vales.respuesta.map(v => v.Total).reduce((a, b) => a + b, 0),
                        totalPendientes: vales.respuesta.filter(v => v.Estado === 'PENDIENTE').map(v => v.Total).reduce((a, b) => a + b, 0),
                        totalSelected: vales.respuesta.filter(v => Boolean(v.isChecked)).map(v => v.Total).reduce((a, b) => a + b, 0),
                        isLoading: false
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    extractTiposPagos(tipoPagos) {
        tipoPagos = tipoPagos && tipoPagos.length ? tipoPagos.split(",") : ""
        const tipos = []
        for (let i = 0; i < tipoPagos.length; i += 2) {
            tipos.push({
                tipo: tipoPagos[i],
                monto: tipoPagos[i + 1]
            })
        }
        return tipos
    }

    handleVerVale(IdVale) {
        return () => {
            this.props.history.push(`/vales/get/${IdVale}`)
        }
    }

    handleSavePDF() {
        let doc = new jsPDF({});
        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text("REPORTE DE VALES", 85, 10);
        doc.autoTable({
            body: this.state.vales,
            theme: "grid",
            columnStyles: {
                Total: {
                    columnWidth: "wrap",
                    overflow: "linebreak",
                    halign: "right",
                    valign: "middle"
                }
            },
            columns: [
                {header: 'Fecha', dataKey: 'FechaEmision'},
                {header: 'Tipo Comprobante', dataKey: 'TipoComprobante'},
                {header: 'Serie', dataKey: 'Serie'},
                {header: 'Numero', dataKey: 'NumeroComprobante'},
                {header: 'Moneda', dataKey: 'Abreviatura'},
                {header: 'Total', dataKey: 'Total'}
            ]
        });
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("reporte_vales.pdf");
    }

    onChangeFechaInicioHandler(e) {
        this.setState({fechaInicio: e.target.value});
    }

    onChangeFechaFinHandler(e) {
        this.setState({fechaFin: e.target.value});
    }

    onCheckVale(e, idVale) {
        let tmpVales = [...this.state.vales];
        let vale = tmpVales.find(v => String(v.IdVale) === String(idVale));
        vale.isChecked = !vale.isChecked;
        this.setState({
            vales: tmpVales,
            totalSelected: tmpVales.filter(v => v.isChecked).map(v => v.Total).reduce((a, b) => a + b, 0),
        });
    }

    onSelectAllVales() {
        let tmpVales = [...this.state.vales].map(v => {
            return {
                ...v,
                isChecked: v.Estado === 'PENDIENTE'
            }
        })
        this.setState({
            vales: tmpVales,
            totalSelected: tmpVales.filter(v => v.isChecked).map(v => v.Total).reduce((a, b) => a + b, 0),
        })
    }

    onSelectTwentyVales() {
        let tmpVales = [];

        const calculatePedingVales = (arr) => {
            return arr.reduce((acc, curr) =>{
                if (curr?.Estado === 'PENDIENTE') {
                    return ++acc;
                }
                return acc;
            }, 0);
        }

        for (const vale of this.state.vales) { 
            const pendingVales = calculatePedingVales(tmpVales);

            if (pendingVales < 20 && vale?.Estado === 'PENDIENTE') {
                tmpVales.push({
                    ...vale,
                    isChecked: true
                });
            } else {
                tmpVales.push({
                    ...vale,
                    isChecked: false
                });
            }
        }

        if (calculatePedingVales(tmpVales) < 20) {
            notificarMsg("Se han seleccionado menos de 20 vales.")
        }

        this.setState({
            vales: tmpVales,
            totalSelected: tmpVales.filter(v => v.isChecked).map(v => v.Total).reduce((a, b) => a + b, 0),
        })
    }

    onProcesarVales() {
        const idsVales = this.state.vales.filter(v => v.isChecked).map(v => v.IdVale);

        if (idsVales.length) {
            window.sessionStorage.setItem(VALES_TIME_RANGE_SESSION_KEY, JSON.stringify({
                fechaInicio: this.state.fechaInicio,
                fechaFin: this.state.fechaFin
            }));

            return this.props.history.push(`/vales/procesar/${idsVales.join(',')}?cliente=99999999`)
        } else {
            notificarError("Debe seleccionar vales a procesar")
        }
    }

    reCalculateVales(vales) {
        this.setState({
            total: vales.map(v => v.Total).reduce((a, b) => a + b, 0),
            totalPendientes: vales.filter(v => v.Estado === 'PENDIENTE').map(v => v.Total).reduce((a, b) => a + b, 0),
            totalSelected: vales.filter(v => v.isChecked).map(v => v.Total).reduce((a, b) => a + b, 0),
        })
    }

    goToPreventa(idPreventa) {
        this.props.history.push(`/pre-invoice/add/${idPreventa}`)
    }

    openModalValidacion(objAnular) {
        this.setState({valeInfo: objAnular, showModalValidacion: true})
    }

    handleChangeCodigoAnular = (text) => {
        const value = text.target.value
        this.setState({ codigoValidacionInput: value });
    }

    async validarCodigoUsuario() {
        const codigoInput = this.state.codigoValidacionInput;
        const codigoUsuario = this.state.codigoValidacion;

        if (codigoInput != codigoUsuario) {
            notificarMsg('El codigo ingresado no es correcto.', 'warning')
        } else {
            notificarMsg("Validacion completada exitosamente.");
            this.anularValeHandler(this.state.valeInfo)
        }

        this.setState({showModalValidacion: false})
        this.setState({codigoValidacionInput: ""})
    }

    handleCloseModalValidarAnular = (e) => {
        this.setState({ valeInfo: null, showModalValidacion: false })
    }

    render() {
        const styleCalendar = {
            border: "0.5px solid #acacac",
            borderRadius: "25px",
            outline: "none",
            flexBasis: "50%",
            alignSelf: "center"
        };

        return (
            <section className="ventas-fpay-section">
                <div className="pt-5">
                    <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                        <span className="align-self-center letra-fecha-fpay">Desde: </span>
                        <input
                            type="date"
                            onChange={this.onChangeFechaInicioHandler}
                            value={this.state.fechaInicio}
                            className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                            style={styleCalendar}
                        />
                        <span className="align-self-center letra-fecha-fpay">Hasta: </span>
                        <input
                            type="date"
                            onChange={this.onChangeFechaFinHandler}
                            value={this.state.fechaFin}
                            className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                            style={styleCalendar}
                        />
                    </div>
                </div>
                <div className="container pt-3">
                    <div className="mt-3 no-pad">

                        <SucursalPicker colStyle={true} checkPermission={true} onChangeSucursal={d => {
                            d = d && d.length ? d : null
                            this.setState({ idSucursal: d })
                        }} />
                    </div>           
                    <div className="col-sm no-pad seccion-btn-verde-mfp">
                            <button className="btn btn-outline-primary btn-verde-mfp" href="#" onClick={e => {
                                e.preventDefault()
                                this.props.history.push("/vales/reportes/sucursales")
                            }}>Reporte por sucursales</button>
                    </div>
                    <div className="caja-fondo-blanco-m-2 mt-3">
                        <div className="col-sm-auto sucursal-text-color no-pad">
                            Cliente
                        </div>
                        <div className="col-sm no-pad">
                            <AsyncSelect
                                defaultOptions={true}
                                onChange={this.onChangeCliente}
                                value={this.state.cliente}
                                loadOptions={this.searchClients}
                                isClearable={true}
                            />
                        </div>
                    </div>
                   
                    <div className="mt-1 seccion-btn-verde-mfp">
                            <button onClick={() => this.getVales(this.state.fechaInicio, this.state.fechaFin, this.state.cliente ?
                                    this.state.cliente.value : null, this.state.idSucursal)} className="btn btn-outline-primary btn-verde-mfp">
                                Filtrar
                            </button>
                        </div>
                </div>
                {this.state.isLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div>
                ) : <ValesUI
                    {...this.state}
                    goToPreventa={this.goToPreventa}
                    history={this.props.history}
                    onSelectAllVales={this.onSelectAllVales}
                    handleVerVale={this.handleVerVale}
                    onProcesarVales={this.onProcesarVales}
                    handleAnularVale={this.anularValeHandler}
                    pdfViewer={this.handleSavePDF}
                    onCheckVale={this.onCheckVale}
                    onSelectFirstTwenties={this.onSelectTwentyVales}
                    reCalculateVales={this.reCalculateVales}
                    handleChangeCodigoAnular={this.handleChangeCodigoAnular}
                    openModalValidacion={this.openModalValidacion}
                    validarCodigoUsuario={this.validarCodigoUsuario}
                    handleCloseModalValidarAnular={this.handleCloseModalValidarAnular}
                />}

            </section>
        );
    }
}

export default Vales;
